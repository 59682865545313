import React, { useMemo } from "react";

// eslint-disable-next-line
import Symbol_observable from "symbol-observable";

import "react-toastify/dist/ReactToastify.css";
import "./assets/stylesheets/App.scss";
import "./assets/stylesheets/Notifications.scss";

import { BrowserRouter, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "react-oidc-context";
import { useTranslation } from "react-i18next";

import AppRoutes from "./components/AppRoutes";
import GlobalNav from "./components/GlobalNav/GlobalNav";
import LoginToSaveModal from "./components/Modals/LoginToSaveModal";
import ToastCloseButton from "./utils/ToastCloseButton";
import SettingsProvider from "./utils/SettingsProvider";
import SecondaryNav from "./components/SecondaryNav/SecondaryNav";
import CustomApolloProvider from "./utils/CustomApolloProvider";
import oidcConfig from "./utils/oidcConfig";
import Footer from "./components/Footer/Footer";
import BetaModal from "./components/Modals/BetaModal";

const App = () => {
  const isEmbedded = useSelector((state) => state.editor.isEmbedded);
  const userType = useSelector((state) => state.editor.userType);
  const schoolCode = useSelector((state) => state.school.autofillSchoolCode);

  const StudentAuthProvider = ({ children }) => {
    const authProviderProps = useMemo(() => {
      return oidcConfig("student", schoolCode);
    }, []);

    return (
      <AuthProvider {...authProviderProps}>
        <div data-testid="student-auth-provider">{children}</div>
      </AuthProvider>
    );
  };

  const FullAuthProvider = ({ children }) => {
    const authProviderProps = useMemo(() => {
      return oidcConfig();
    }, []);

    return (
      <AuthProvider {...authProviderProps}>
        <div data-testid="full-auth-provider">{children}</div>
      </AuthProvider>
    );
  };

  const UserAuthProvider =
    userType === "student" ? StudentAuthProvider : FullAuthProvider;

  if (!UserAuthProvider) return null;

  return (
    <UserAuthProvider userType={userType}>
      <CustomApolloProvider>
        <BrowserRouter>
          <AppContent isEmbedded={isEmbedded} />
        </BrowserRouter>
      </CustomApolloProvider>
    </UserAuthProvider>
  );
};

const AppContent = ({ isEmbedded }) => {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const location = useLocation();
  const isSelectUserTypePage = location.pathname === `/${locale}/login`;

  return (
    <SettingsProvider id="app">
      <ToastContainer
        enableMultiContainer
        containerId="top-center"
        position="top-center"
        className="toast--top-center"
        closeButton={ToastCloseButton}
      />

      {!isEmbedded && !isSelectUserTypePage && (
        <>
          <GlobalNav />
          <SecondaryNav />
        </>
      )}

      <AppRoutes />
      <Footer />

      <BetaModal />
      <LoginToSaveModal />
      <ToastContainer
        enableMultiContainer
        containerId="bottom-center"
        position="bottom-center"
        className="toast--bottom-center"
      />
    </SettingsProvider>
  );
};

export default App;
