import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.I18N_DEBUG === "true",
    fallbackLng: "en",
    locales: [
      "en",
      "ar-SA",
      "ca-ES",
      "cs-CZ",
      "me-ME",
      "cy-GB",
      "da-DK",
      "de-DE",
      "el-GR",
      "et-EE",
      "es-ES",
      "es-LA",
      "fr-FR",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "it-IT",
      "ja-JP",
      "kn-IN",
      "ko-KR",
      "mr-IN",
      "hu-HU",
      "nl-NL",
      "no-NO",
      "pl-PL",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "sk-SK",
      "sl-SI",
      "fi-FI",
      "sv-SE",
      "vls-BE",
      "sr-SP",
      "tr-TR",
      "uk-UA",
      "zh-CN",
      "zh-TW",
    ],
    // currently supportedLngs is the same as locales but clever lang loading
    // could reduce the number of supported translation files e.g. 'fr' over 'fr-FR' + variants
    supportedLngs: [
      "en",
      "ar-SA",
      "ca-ES",
      "cs-CZ",
      "me-ME",
      "cy-GB",
      "da-DK",
      "de-DE",
      "el-GR",
      "et-EE",
      "es-ES",
      "es-LA",
      "fr-FR",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "it-IT",
      "ja-JP",
      "kn-IN",
      "ko-KR",
      "mr-IN",
      "hu-HU",
      "nl-NL",
      "no-NO",
      "pl-PL",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "sk-SK",
      "sl-SI",
      "fi-FI",
      "sv-SE",
      "vls-BE",
      "sr-SP",
      "tr-TR",
      "uk-UA",
      "zh-CN",
      "zh-TW",
    ],
    load: "currentOnly", // otherwise for fr-FR it's load ['fr-FR', 'fr']

    // nonExplicitSupportedLngs: true, // allows locale variants on supportedLngs
    detection: {
      order: ["path"], // only use path to detect local for now
    },

    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    resources: {
      en: {
        translation: {
          modal: {
            close: "Close",
            error: {
              heading: "An error has occurred",
              externalLink: {
                message:
                  "Unfortunately links to external sites are not available in the Editor.",
              },
            },
          },
          betaBanner: {
            buttonLabel: "close",
            buttonTitle: "close",
            message: "The Code Editor is in",
            feedbackText: "Give us your",
            feedback: "feedback",
            feedbackImprove: "to help us improve.",
            modal: {
              close: "Close",
              heading: "Code Editor is in beta",
              meaningHeading: "What does beta mean?",
              meaningText:
                "Beta means that we are not quite finished yet, and some things might not look or work as well as we’d like. However, it also means you are one of the first people to use our new Code Editor!",
            },
            modalLink: "beta",
          },
          betaFeedback: {
            title: "Feedback & updates",
            beta: "beta",
            text: "Code Editor for Education is currently in <0>beta</0>. Complete this <1>survey</1> to share your feedback.",
            productUpdates: {
              date: "🚀 Latest updates",
              list: [
                "View students who have finished their projects",
                "Add additional teachers to your class",
              ],
              link: "View full updates",
            },
          },
          classes: {
            teacherTitle: "Classes",
            teacherDescription:
              "Classes are used to group students and assign projects to them.",
            studentTitle: "Your classes",
            studentDescription:
              "Here are all the classes you have been added to.",
            createClass: "Create class",
            noClasses: "You don't have any classes at the moment",
          },
          classMembers: {
            noMembers: "This class doesn't have any members yet",
          },
          classModal: {
            create: {
              title: "Create a new class",
              confirm: "Create class",
              cancel: "Cancel",
            },
            update: {
              title: "Edit class details",
              confirm: "Update class details",
              cancel: "Cancel",
            },
            description:
              "Classes are used to group students and assign projects to them. We recommend using a consistent naming convention to help you to identify your classes easily.",
            className: "Class name",
            classNameHint: "Your class name will be visible to your students",
            classDescription: "Class description (optional)",
            classDescriptionHint:
              "Your class description will be visible to your students",
            nameMissing: "You must supply a name",
          },
          editorHome: {
            text: "Code Editor home",
          },
          editorPanel: {
            ariaLabel: "editor text input",
          },
          filePanel: {
            errors: {
              containsSpaces: "File names must not contain spaces.",
              generalError: "Error",
              notUnique: "File names must be unique.",
              or: "or",
              unsupportedExtension:
                "File names must end in {{allowedExtensions}}.",
            },
            files: "Project files",
            images: "Image gallery",
            newFileButton: "Add file",
            newFileModal: {
              cancel: "Cancel",
              heading: "Add a new file to your project",
              helpText:
                "Remember to add the file extension at the end of your file name, for example, {{examples}}",
              helpTextExample: {
                html: "'file.html', 'file.css' or 'file.js'",
                python: "'file.py'",
              },
              inputLabel: "Name your file",
              addFile: "Add file",
            },
            renameFileModal: {
              cancel: "Cancel",
              heading: "Rename file",
              inputLabel: "Name your file",
              save: "Save",
            },
            fileMenu: {
              label: "Open file menu",
              renameItem: "Rename file",
            },
          },
          downloadPanel: {
            heading: "Save & download",
            logInTitle: "Log in to save your progress",
            logInHint:
              "With a Raspberry Pi Account you can save your code and project steps progress.",
            logInButton: "Log in to save",
            signUpButton: "Sign up",
            downloadHint:
              "Download your project files so you can use them offline and in a different code editor.",
            downloadButton: "Download project",
          },
          landingPage: {
            title: "Code Editor",
            subtitle: "Start coding, no setup required!",
            python: "Start coding Python",
            html: "Start coding HTML/CSS",
            login: "Log in",
            start: "Not sure where to start?",
            projectPython: "Python path",
            projectHtml: "Web path",
          },
          educationLandingPage: {
            hero: {
              title: "Same great code editor, now in your classroom.",
              subtitle: "Free now, free forever.",
              imageAlt: "A young girl using the Code editor in a classroom.",
              createSchool: "Create your school account",
              logIn: "Log in as a student",
              schoolDashboard: "View your school",
            },
            beta: {
              title: "Now in beta!",
              text: "Code Editor For Education is currently in beta. This means you get to use the platform sooner, but not all features are complete. If you’d like to know more, see our <0>help articles</0>.",
            },
            getStarted: {
              title: "Register your school to get started!",
              button: "Create your school account",
            },
            ide: {
              title: "An IDE designed for learners",
              text: "Tailored specifically to young people's needs, our integrated development environment (IDE) helps make learning text-based programming simple and accessible for children aged 9 and up. It’s safe, age-appropriate, and suitable for use in the classroom.",
              imageAlt:
                "A screenshot of the Code Editor showing a Python project that generates geometric patterns from a string. Alongside the code there are some instructions for the student",
            },
            free: {
              title: "All features, totally free",
              text: "Access the Code Editor in your web browser without installing any additional software. Add an unlimited number of projects, lessons, teachers, and students to your school account, all for free.",
            },
            engage: {
              title: "Create engaging coding lessons",
              text: "Create custom coding projects for your students to work on. Help them code their own games and art using Python, or design websites in HTML/CSS/JavaScript. Your students can get creative with code with our wide choice of Python libraries!\n\nYou can also give your students guidance and support by adding custom instructions to the projects you share with them.",
              imageAlt:
                "A collage of images showing different visual outputs from the Code Editor. Such as, geometric patterns, a solar system simulation and a map visualisation.",
            },
            feedback: {
              title: "Give students personalised feedback",
              text: "Easily check your students’ progress, view their coding projects, and share individual, instant feedback with each student to keep them on track.",
              imageAlt:
                "A screenshot from the Code Editor which shows a teacher’s comment on a student's code. The Teacher's comment reads “Looks great! Just one small mistake to fix”. Referring to a typo in the student's code.",
              tag: "Future feature",
            },
            class: {
              title: "Simple and easy class management",
              text: "Like the Editor itself, we’ve kept our educator interface clean, simple, and easy to use following feedback from teachers. Create and manage student accounts easily. You can add students in bulk through a .csv file, organise your students into classes and help them reset their passwords quickly.",
            },
            safe: {
              title: "Safe and private by design",
              text: "Accounts for education are designed to be safe for students of all ages. We take safeguarding seriously, with one-way feedback to students, visibility of their work at all times, and verified school accounts.\n\nIn line with best practice codes protecting children online, we minimise our data capture so that we have just enough to keep students safe.",
            },
            people: {
              title: "What do people say about our Editor?",
              text: "<i>“We have used it and love it, the fact that it is both for HTML / CSS and then Python is great as the students have a one-stop shop for IDEs.”</i><br/><strong>Lee Willis, Head of ICT and Computing, Newcastle High School for Girls</strong><br/><br/><i>“The class management and feedback features they're working on at the moment look really promising.”</i><br/><strong>Pete Dring, Head of Computing, Fulford School</strong>",
              imageAlt: "Students working on computers in a classroom.",
            },
          },
          educationLogo: {
            codeEditor: "Code Editor",
            forEducation: "For Education",
          },
          multiStepForm: {
            cancel: "Cancel",
            continue: "Continue",
            back: "Back",
            submit: "Submit",
            errorTitle: "There is a problem with the form",
          },
          invitation: {
            confirmation: {
              title: "You joined {{schoolName}}!",
              button: "Go to School Dashboard",
            },
            invalidToken: {
              title: "You cannot accept this invite with this account",
              explanationOne:
                "It looks like you've been invited to join a school, but you're logged in with a different Raspberry Pi account. To accept the invitation, please log in or create an account using the email address to which the invitation was sent, and then try opening the invitation link again.",
              explanationTwo:
                "If you continue to have issues, please contact your school owner for assistance.",
              cancel: "Cancel",
            },
            start: {
              subtitle: "You have been invited to join:",
              continue: "Continue",
            },
            responsibilities: {
              title: "Do you accept these responsibilities?",
              listIntro: "As a <strong>teacher</strong> you must agree to:",
              listItemOne:
                "Be responsible for how students use the platform within your school account.",
              listItemTwo:
                "Make sure that features are used in line with your school’s internal policies and practices.",
              fullResponsibilities:
                "Your full responsibilities are outlined in the school account <0>Terms and Conditions</0>, so please ensure you read these.",
              acceptTerms:
                "I accept the responsibilities and the Terms and Conditions.",
              changeEmail:
                "I understand I will need to contact the Raspberry Pi Foundation to change my email address or delete my Raspberry Pi Foundation account while I am part of this school account.",
              back: "Back",
              joinSchool: "Join school",
              errorTitle: "There is a problem with the form",
              errorText:
                "You must agree to the responsibilities and Terms and Conditions to continue.",
            },
          },
          schoolOnboarding: {
            step: "Step title",
            steps: {
              errors: {
                401: "You must be logged in to create a school.",
                500: "There was an error processing the form. Please try again later.",
                unknown:
                  "An unknown error occurred. Please refresh and try again later.",
              },
              step1: {
                title: "Before you start creating your school account",
                thingsToKnow:
                  "There are a few things you need to know before you set up an account for your school:",
                employee:
                  "You need to be an <0>employee of the school</0>, however you do not need to be a teacher.",
                owner:
                  "You will become the <0>owner of the school account</0>. This comes with responsibilities you will need to agree to.",
                verification:
                  "Your school will need to be <0>verified by our team</0>. We will contact you within 5 working days.",
                email:
                  "You will <0>not be able to change your email address</0> or delete your Raspberry Pi Foundation account while you are the owner of the school account. You will have to contact us to do this.",
                notSchoolQuestion:
                  "Can I use this if I am not part of a school?",
                notSchoolAnswer:
                  "No, Code Editor for Education is only available to schools at this time.",
              },
              step2: {
                title: "Do you agree to be responsible for the school account?",
                owner:
                  "Creating the school account will make you the <0>owner</0>.",
                responsibilities:
                  "As an account <0>owner</0> you must agree to:",
                responsibility1:
                  "Have the authority to create student accounts within your school account.",
                responsibility2:
                  "Be responsible for how students use the platform within your school account.",
                responsibility3:
                  "Make sure that features are used in line with your school's internal policies and practices.",
                termsAndConditions:
                  "Your full responsibilities are outlined in the school account <0>Terms and Conditions</0>, so please ensure you read these.",
                agreeAuthority:
                  "I have the authority to create this account on behalf of my school.",
                agreeTermsAndConditions:
                  "I agree to be responsible for this school account and I accept the <0>Terms and Conditions</0>.",
                subscribeToResearch:
                  "I agree to being contacted about user research opportunities in relation to Code Editor for Education. I can unsubscribe at any time. (optional)",
                validation: {
                  errors: {
                    message:
                      "You must agree to the following responsibilities and Terms and Conditions to continue.",
                    agreeAuthority: "You must confirm you have authority",
                    agreeTermsAndConditions:
                      "You must confirm you accept responsibility",
                  },
                },
              },
              step3: {
                title: "What is your role at the School?",
                optionalInfo:
                  "Providing this information is optional, however it helps us to understand our users, how the platform is used, and how we can improve it.",
                role: "What is your role at the School? (Optional)",
                otherRole: "Please tell us your role",
                select: "Please select",
                teacher: "Teacher",
                headOfDepartment: "Head of Department",
                admin: "Administrative staff",
                other: "Other",
                department: "What department are you part of? (Optional)",
                departmentHint:
                  "For example, Computing, IT, Science, English, Art, etc.",
              },
              step4: {
                title: "Tell us about your school",
                schoolDetails:
                  "In order to set up your school in the Editor, we require some details about your school so we can verify it.",
                schoolName: "School name",
                schoolWebsite: "School website",
                schoolAddress: "School address",
                schoolAddress1: "Address line 1",
                schoolAddress2: "Address line 2 (Optional)",
                schoolCity: "Village/Town/City",
                schoolState: "State/County/Province",
                schoolPostcode: "Postal code/Zip code",
                schoolCountry: "Country",
                select: "Please select",
                schoolUrn: "School URN (Optional)",
                schoolUrnHint:
                  "This can be found on the UK Government website <0>here</0>. Although this is not required it will help us to verify your school account.",
                validation: {
                  errors: {
                    message: "Please check the fields.",
                    schoolName: "You must supply your school's name",
                    schoolWebsite: "The school website doesn't look right.",
                    schoolAddress1:
                      "You must supply Address line 1 of your school.",
                    schoolCity:
                      "You must supply your school's Village/Town/City.",
                    schoolState:
                      "You must supply your school's State/County/Province.",
                    schoolPostcode:
                      "You must supply your school's Postal code/Zip code.",
                    schoolCountry: "Please select a country from the list.",
                  },
                },
              },
            },
            error: {
              title: "Sorry, there's been a problem loading the form.",
              copy: "Please try again later.",
              tryAgain: "Try again",
            },
          },
          schoolAlreadyExists: {
            title: "You already have a School account",
            explanation:
              "There's already an account linked to this Raspberry Pi Foundation account. If you wish to create another account for a different school, you will need to use a different Raspberry Pi Foundation account.",
            contact:
              "If you have any issues you can contact us via email: <0>code.editor.registrations@raspberrypi.org</0>.",
            exploreProjects: "Explore our projects",
            editorHome: "Code Editor home",
          },
          schoolBeingVerified: {
            title: "Your school account is being verified",
            text: "You have already set up a school account and it is now in the process of being verified. If you wish to set up another account for a different school, you must use a different Raspberry Pi Foundation account.",
            next: "What happens next?",
            contact:
              "If you have any issues you can contact us via email: <0>code.editor.registrations@raspberrypi.org</0>. Please wait for 5 working days before contacting us about verifying your school.",
            listItems: {
              item1:
                "Thank you for providing the all the information needed to set up your school account.",
              item2:
                "We will verify your school. We will contact you within 5 working days and you'll receive a confirmation email once it's been verified.",
              item3:
                "Once your school has been verified, you will be able to log in to the Code Editor with your Raspberry Pi Foundation account and access your school dashboard.",
            },
            exploreProjects: {
              text: "Explore our projects",
              url: "https://projects.raspberrypi.org",
              plausible: "Explore our projects",
            },
            editorHome: {
              text: "Code Editor home",
              url: "/",
              plausible: "Code Editor home",
            },
          },
          schoolCreated: {
            title: "School account created!",
            text: "Thank you for setting up your school account in the Code Editor!",
            next: "What happens next?",
            contact:
              "If you have any issues you can contact us via email: <0>code.editor.registrations@raspberrypi.org</0>. Please wait for 5 working days before contacting us about verifying your school.",
            listItems: {
              item1:
                "Thank you for providing all the information needed to set up your school account.",
              item2:
                "We will verify your school. We will contact you within 5 working days and you'll receive a confirmation email once it's been verified.",
              item3:
                "Once your school has been verified, you will be able to log in to the Code Editor with your Raspberry Pi Foundation account and access your school dashboard.",
            },
            exploreProjects: {
              text: "Explore our projects",
              url: "https://projects.raspberrypi.org",
              plausible: "Explore our projects",
            },
            editorHome: {
              text: "Code Editor home",
              url: "/",
              plausible: "Code Editor home",
            },
          },
          schoolDashboard: {
            copyLink: "Copy link",
            loginCode: "School login code",
            loginCodeExplanation:
              "Students will need to use your school code on the student login page.",
            manageMembers: "Manage members",
            error: {
              title: "Sorry, there's been a problem loading your dashboard.",
              copy: "Please log out, or try again later.",
              tryAgain: "Try again",
            },
          },
          lessonPage: {
            copyLessonModal: {
              cancel: "Cancel",
              copy: "Copy to classes",
              description:
                "Copy this project to reuse the existing project code. Existing student work will not be copied.",
              noClassesSelected: "No classes selected",
              noResults: "No classes found",
              search: "Find a class",
              selectedClasses: "Copy project to:",
              thisClass: "(This class)",
              title: "Create a copy of this project",
            },
            copyLesson: "Copy project",
            editLesson: "Edit project",
            hideLesson: "Hide from students",
            lessonSettings: "Lesson settings",
            showLesson: "Show to students",
            viewCode: "View project code",
          },
          lessons: {
            addProject: "Add project",
            description:
              "Projects are shared with students and contain starter code created by a teacher.",
            noProjects: "No projects",
            title: "Projects",
          },
          validationErrors: {
            nameLength:
              "You must supply a name that's at least 5 characters long",
            usernameLength:
              "You must supply a username that's at least 5 characters long",
            passwordLength:
              "You must supply a password that's at least 8 characters long.",
          },
          memberFilters: {
            owners: "Owners",
            teachers: "Teachers",
            students: "Students",
          },
          membersPage: {
            title: "School members",
            editMember: {
              editDetails: "Edit details",
              changePassword: "Change password",
            },
            inviteTeacher: {
              buttons: {
                cancel: "Cancel",
                createTeacher: "Invite teacher",
              },
              heading: "Invite a teacher",
              teacherPermissions: "Teachers are able to:",
              teacherList: [
                "View and create classes and projects. In future, they will be able to edit and delete these too.",
                "Share projects with students and view their work.",
                "View, create, and edit student accounts. They can also remove students from classes.",
              ],
              ownerPermissions:
                "Owners have the same permission as teachers but can also:",
              ownerList: [
                "Invite and remove teachers.",
                "In future, they will be able to edit the school's details.",
                "In future, they will be able to invite teachers to become owners.",
              ],
              form: {
                label: "Teacher’s email address",
                hint: "This will be the email address that receives the invite and that the teacher will use to log in to the school account.",
                errors: {
                  title: "The invitation couldn't be sent",
                  api: "Sorry, there was a problem submitting the form, please try again later.",
                  presence: "Error: you must provide an email address",
                  invalid: "Error: email address is not valid",
                },
                success: {
                  title: "The teacher has been invited",
                  text: "The teacher will soon receive an email where they can log in to the school account.",
                },
              },
            },
            noMembersFound: "No members found",
            createStudentChoice: {
              title: "Create new students",
              text: "How many students do you want to add?",
              single: "Add a single student",
              multiple: "Add multiple students",
              cancel: "Cancel",
              continue: "Continue",
            },
            createSingleStudent: {
              title: "Add a single student",
              explanation:
                "You will need to provide these details to the student for them to log in.",
              name: "Student's name",
              nameHint:
                "This should be the student's real name. This will only be visible to teachers.",
              nameMissing: "You must supply a name",
              username: "Username",
              usernameHint:
                "This username should be unique within this school account and should NOT contain the student's real name. If your school already has unique identifiers for students, we recommend using these.",
              usernameMissing: "You must supply a username",
              password: "Temporary password",
              passwordHint:
                "Passwords must be at least 8 characters long and difficult to guess (see Help for more information). You will need to share this password with the student. They will choose their own password when they log in for the first time.",
              passwordLength:
                "You must supply a password that's at least 8 characters long.",
              createStudent: "Add student",
            },
            createMultipleStudents: {
              title: "Bulk create student accounts",
              explanation:
                "Create accounts in bulk using <0>this CSV format</0>.",
              rowWarning:
                "Your CSV file should not contain more than {{limit}} rows.",
              csvUploadHint: "<0>Choose a file</0> or drag it here",
              removeUpload: "Remove upload",
              fileUploaded: "File uploaded",
              createStudents: "Add students",
              errorTitle: "Errors found when creating new students",
              errorDescription:
                "Please correct these errors in your CSV file, reupload, and try again.",
              reUpload: "Reupload CSV",
              requestedTitle: "Bulk student creation requested",
              requestedExplanation:
                "Creating students in bulk can take some time depending on the number of students created. You can see the status of this request on the School members page.",
              done: "Done",
              tryAgain: "Try again",
              csvUnexpectedHeaders:
                "Error: Your CSV file has unexpected headers, please check your CSV is following the format provided.",
              csvInvalidRows:
                "Error: Your CSV file has invalid rows, please check your CSV is following the format provided.",
              csvTooManyRows:
                "Error: You have more than {{limit}} rows in your CSV file.",
              jobConcurrencyError: {
                title: "Unable to create students in bulk",
                description:
                  "A request to create multiple students is already in progress, please allow this to complete before making another request. You can see the status of this request on the School members page.",
              },
              standardError: {
                title:
                  "We encountered an issue when trying to create these students",
              },
              addingStudents: "Adding students",
            },
            changeStudentPassword: {
              title: "Change student's password",
              text: "New temporary password",
              password: "New temporary password",
              passwordHint:
                "Passwords must be at least 8 characters long and difficult to guess (see Help for more information). You will need to share this password with the student. They will choose their own password when they next log in.",
              cancel: "Cancel",
              continue: "Continue",
            },
            editStudentDetails: {
              title: "Edit student details",
              studentName: "Student name",
              studentNameExplanation:
                "This should be the student’s real name. This will only be visible to teachers.",
              studentUsername: "Student username",
              studentUsernameExplanation:
                "This username should be unique within this school account and should NOT contain the student’s real name. We recommend using a unique identifier that is already used in your school for this student.",
              cancel: "Cancel",
              save: "Save changes",
              errorName: "This name already exists",
              errorUsername: "This username already exists",
            },
            jobStatus: {
              information: {
                title:
                  "In progress: {{studentCount}} students are currently being created",
                text: "{{studentCount}} students are being created, this will take some time to finish. All the students will be seen here once completed.",
              },
              success: {
                title: "Complete: {{studentCount}} students have been created",
                text: "You can now add these students to classes.",
              },
              error: {
                title: "Failed: Unable to create {{studentCount}} students",
                text: "Your request to create multiple students has failed. If this has happened we are aware of the problem and are actively trying to resolve this!",
              },
            },
          },
          membersPageHeader: {
            title: "School members",
            text: "These are all the members in your school, including students, teachers, and owners. Please get in touch with us at websupport@raspberrypi.org to remove a teacher from your school.",
            invite: "Invite teacher",
            create: "Add student",
          },
          classMembersPage: {
            addMembers: "Add member to class",
            classMembers: "Class members",
            title: "Class members ({{numberOfMembers}})",
            yourSchool: "Your School",
            menu: {
              changePassword: "Change password",
              removeFromClass: "Remove from class",
            },
            addMembersModal: {
              add: "Add selected members",
              cancel: "Cancel",
              description:
                "Adding <0>students</0> to a class will allow them to access all the visible projects within the class.",
              inClass: "In class",
              noResults: "No members found",
              noStudentsSelected: "No school members selected",
              search: "Search for an existing school member to add",
              selectedStudents: "Students that will be added",
              title: "Class members",
            },
            removeStudent: {
              title: "Remove member from class",
              text: "You are about to remove <strong>{{name}}</strong>.",
              panelTitle: "Removing a student",
              panelText:
                "This removes them from the class, but does not delete the student or their work from the school account. Any work they’ve done will remain in the class.",
              cancel: "Cancel",
              remove: "Remove from class",
            },
          },
          classPage: {
            classMembers: "Class members",
            yourSchool: "Your School",
            classSettings: "Class settings",
            delete: "Delete class",
            edit: "Edit class",
            duplicate: "Duplicate class",
          },
          footer: {
            accessibility: "Accessibility",
            charityNameAndNumber:
              "Raspberry Pi Foundation UK registered charity 1129409",
            cookies: "Cookies",
            privacy: "Privacy",
            safeguardingConcern: "Do you have a safeguarding concern?",
            contactUs: "Report a concern",
            safeguarding: "Safeguarding",
            termsAndConditions: "Terms & Conditions",
            help: "Help",
            feedback: "Give Feedback",
          },
          globalNav: {
            accountMenu: {
              login: "Log in",
              logout: "Log out",
              profile: "My profile",
              projects: "My projects",
            },
            accountMenuDefaultAltText: "Account menu",
            accountMenuProfileAltText: "{{name}}'s account",
            raspberryPiLogoAltText: "Raspberry Pi logo",
          },
          projectName: {
            label: "Project name",
            newProject: "New Project",
          },
          header: {
            download: "Download",
            downloadFileNameDefault: "my {{project_type}} project",
            editorLogoAltText: "Editor logo",
            projects: "Your projects",
            renameProject: "Edit project name",
            renameSave: "Save project name",
            save: "Save",
            loginToSave: "Log in to save",
            settings: "Settings",
          },
          imagePanel: {
            gallery: "Image Gallery",
          },
          infoPanel: {
            info: "Information",
          },
          instructionsPanel: {
            nextStep: "Next step",
            previousStep: "Previous step",
            projectSteps: "Project steps",
          },
          projectsPanel: {
            projects: "Projects",
            yourProjectsButton: "Go to your projects",
            projectTypeLabel: "Project type",
          },
          settingsPanel: {
            info: "Settings",
          },
          input: {
            comment: {
              py5: "Py5: imported mode",
            },
          },
          loginToSaveModal: {
            cancel: "Cancel",
            downloadButtonText: "Download",
            downloadText:
              "Or you can download your project and save it on your computer.",
            heading: "Save your project",
            loginButtonText: "Log in to save",
            loginText:
              "Log in to your Raspberry Pi account to save your work, and you'll be able to access and edit your project whenever you need to.",
          },
          mobile: {
            code: "Code",
            menu: "Menu",
            output: "Output",
            preview: "Preview",
            steps: "Steps",
          },
          modals: {
            close: "Close",
          },
          projectModal: {
            cancel: "Cancel",
            createHeading: "Create a new project",
            createProject: "Create project",
            projectName: {
              default: "Untitled",
              helpText: "You can always rename your project later",
              helpTextForLesson:
                "Your project name is visible to your students.",
              inputLabel: "Project name",
              inputLabelForLesson: "Project name",
              missing: "You must supply a project name",
            },
            projectType: {
              html: "HTML",
              inputLabel: "What kind of project do you want to make?",
              inputLabelForLesson:
                "What kind of project do you want to make for your students?",
              helpTextForLesson:
                "This cannot be changed once you have created the project.",
              python: "Python",
              missing: "You must supply a project type",
            },
            updateHeading: "Edit project details",
            updateProject: "Update project",
          },
          notifications: {
            classCreated: "Class created",
            classUpdated: "Class details updated",
            classMemberAdded: "Class member(s) added",
            close: "close",
            lessonCopied: "Project copied to class(es)",
            lessonUpdated: "Project details updated",
            loginPrompt:
              "To save this project and access it later, don't forget to log in or sign up!",
            projectRemixed: "Your remixed project has been saved",
            projectRenamed: "You have renamed your project.",
            projectSaved: "Your project has been saved",
            savePrompt:
              'Save this project to access it later under "Your projects".',
            studentCreated: "Student created",
            studentPasswordChanged: "Student password changed",
            studentDetailsChanged: "Student details changed",
            studentsCreated: "Students created",
            teacherInvited: "Teacher invite sent",
            studentRemovedFromClass: "Student removed from class",
          },
          output: {
            errors: {
              interrupted: "Execution interrupted",
            },
            newTab: "Preview in new tab",
            preview: "preview",
            senseHat: {
              controls: {
                colour: "colour",
                humidity: "humidity",
                motion: "motion",
                motionSensorOptions: {
                  no: "No",
                  yes: "Yes",
                },
                name: "Space Station Control Panel",
                pressure: "pressure",
                temperature: "temperature",
                timer: "timer",
              },
              model: {
                pitch: "pitch",
                roll: "roll",
                yaw: "yaw",
              },
            },
            textOutput: "Text output",
            visualOutput: "Visual output",
          },
          outputViewToggle: {
            buttonTabLabel: "Tabbed view",
            buttonTabTitle: "Tabbed view",
            buttonSplitLabel: "Split view",
            buttonSplitTitle: "Split view",
          },
          project: {
            accessDeniedNoAuthModal: {
              embedded: {
                text: "Visit the Projects site for cool project ideas",
              },
              heading: "You are not able to see this project",
              loginButtonText: "Log in to your account",
              newProject: "Create a new code project",
              projectsSiteLinkText: "Explore Projects site",
              text: "If this is your project, log in to see it. If this is not your project you can visit the Projects site for cool project ideas or to start coding in a new project.",
            },
            accessDeniedWithAuthModal: {
              embedded: {
                text: "Visit the Projects site for cool project ideas",
              },
              heading: "You can't access this project",
              newProject: "Create a new code project",
              projectsSiteLinkText: "Explore Projects site",
              text: "Visit the Projects site for cool project ideas or start coding in a new project.",
            },
            loading: "Loading",
            notFoundModal: {
              embedded: {
                text: "Visit the Projects site for cool project ideas",
              },
              heading: "This project does not exist",
              newProject: "Start new code project",
              projectsSiteLinkText: "Explore Projects site",
              text: "You can start coding in a new project, or visit the Projects site for cool project ideas.",
            },
            untitled: "Untitled project",
          },
          projectHeader: {
            title: "Your projects",
            text: "Select a project to continue coding, view, or edit it.",
          },
          projectList: {
            delete: "Delete",
            deleteLabel: "Delete project",
            deleteProjectModal: {
              cancel: "Cancel",
              delete: "Delete",
              heading: "Delete project",
              text: "Are you sure you want to delete this project?",
            },
            empty: "No projects created yet",
            label: "Open project menu",
            loading: "Loading",
            loadingFailed: "Failed to load projects",
            newProject: "Create a new project",
            pagination: {
              first: "First page",
              last: "Last page",
              next: "Next page",
              previous: "Previous page",
              more: "Load more projects",
            },
            rename: "Rename",
            renameLabel: "Rename project",
            renameProjectModal: {
              cancel: "Do not save",
              heading: "Rename project",
              inputLabel: "Change the name of your project",
              save: "Save",
            },
            updated: "Edited",
            python_type: "Python",
            html_type: "HTML",
          },
          projectPage: {
            back: "Back",
            finished: "Mark as finished",
          },
          projectTypes: {
            html: "HTML/CSS/JS",
            python: "Python",
          },
          runButton: {
            run: "Run",
            stop: "Stop",
            stopping: "Stopping...",
          },
          saveStatus: {
            saving: "Saving",
            saved: "Saved",
          },
          runners: {
            HtmlOutput: "HTML Output Preview",
          },
          secondaryNav: {
            codeEditor: "Code Editor",
            home: "Home",
            education: "For Education",
            projects: "Your Projects",
            school: "Your School",
          },
          sidebar: {
            collapse: "Collapse sidebar",
            download: "Download project",
            expand: "Expand sidebar",
            file: "Project files",
            images: "Image gallery",
            settings: "Settings",
            projects: "Projects",
            information: "Information",
            information_text:
              "Our Code Editor is a tool to help young people learn to code. We have only included functions that are simple and safe to use. That's why, for example, links to other websites are not allowed.",
            instructions: "Project steps",
            feedback: "Feedback",
            privacy: "Privacy",
            cookies: "Cookies",
            accessibility: "Accessibility",
            safeguarding: "Safeguarding",
            charity: "Raspberry Pi Foundation - UK registered charity 1129409",
            settingsMenu: {
              heading: "Settings",
              textSize: "Text size",
              theme: "Theme",
              textSizeOptions: {
                large: "Large",
                medium: "Medium",
                small: "Small",
              },
              themeOptions: {
                dark: "Dark",
                light: "Light",
              },
            },
          },
          studentWork: {
            description:
              "This list contains all the work from students in the class. Only students who have saved their project will appear here. A 'Ready to mark' tag will appear when the student has marked their project as finished.",
            lastEdited: "Last edited: {{time}}",
            noWork: "No student work",
            title: "Student work",
            savingWork: {
              title: "Updating project code and instructions",
              description:
                "Any changes you make will not show to students that have already clicked save in this project.<0/><0/>If you need to edit the code or instructions after students have saved, we recommend creating a new project.<0/><0/>If students have not saved this project, you can hide it then make the changes. When students open the project, they’ll start from the last autosaved version of your code and instructions.",
            },
          },
          webComponent: {
            loading: "Loading",
          },
          webinarBanner: {
            buttonLabel: "close",
            message:
              "👋 Learn more about Code Editor for Education in an upcoming webinar! ",
            registerNow: "Register now.",
          },
          errors: {
            apiErrorTitle: "There was a problem submitting the form",
            missingOption: "You must select an option to continue.",
          },
          loadingStates: {
            loading: "Loading...",
          },
          listItem: {
            readyToMark: "Ready to mark",
          },
          memberTypes: {
            student: "Student",
            teacher: "Teacher",
            owner: "Owner",
          },
          filters: {
            title: "Filter by:",
            description:
              "Selecting a filter option will automatically update the members list",
          },
          userTypeLogin: {
            title: "Are you a teacher or a student?",
            subtitle:
              "You'll need to log into your school account to view this page",
            teacher: "I'm a teacher",
            student: "I'm a student",
            goEditorHome: "Go to Code Editor home",
          },
          copyLink: {
            copied: "Copied!",
            copy: "Copy link",
          },
          unableToAccess: {
            title: "Unable to access this page",
            codeEditorHome: "Code Editor home",
            goToYourSchool: "Go to your school",
            couldBeBecause: "This could be because:",
            doesNotExist: "The page does not exist",
            incorrectLink: "You have used an incorrect link",
            noAccessToPage: "You do not have access to the page",
          },
        },
      },
    },
  });

export default i18n;
