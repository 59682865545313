import { useTranslation } from "react-i18next";
import "../../assets/stylesheets/UnableToAccess.scss";
import DesignSystemButton from "../DesignSystemButton/DesignSystemButton";

const UnableToAccess = () => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;

  return (
    <div className="unable-to-access">
      <div className="unable-to-access__container">
        <div className="unable-to-access___container-content">
          <h1 className="unable-to-access__container-title">
            {t("unableToAccess.title")}
          </h1>
          <div className="unable-to-access__container-description">
            <p> {t("unableToAccess.couldBeBecause")}</p>
            <ul>
              <li> {t("unableToAccess.doesNotExist")}</li>
              <li> {t("unableToAccess.incorrectLink")}</li>
              <li> {t("unableToAccess.noAccessToPage")}</li>
            </ul>
          </div>
        </div>
        <div className="unable-to-access__links">
          <div className="unable-to-access__links-buttons">
            <DesignSystemButton
              text={t("unableToAccess.codeEditorHome")}
              type="secondary"
              textAlways
              href="/"
            />
            <DesignSystemButton
              text={t("unableToAccess.goToYourSchool")}
              type="primary"
              textAlways
              href={`/${locale}/school`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnableToAccess;
