import axios from "axios";
import omit from "lodash/omit";

import { headers, host } from "./shared";

export const createOrUpdateProject = async (projectWithUserId, accessToken) => {
  const project = omit(projectWithUserId, ["user_id"]);
  if (!project.identifier) {
    return await axios.post(
      `${host}/api/projects`,
      { project },
      headers(accessToken),
    );
  } else {
    return await axios.put(
      `${host}/api/projects/${project.identifier}`,
      { project },
      headers(accessToken),
    );
  }
};

export const deleteProject = async (identifier, accessToken) => {
  return await axios.delete(
    `${host}/api/projects/${identifier}`,
    headers(accessToken),
  );
};

export const getImage = async (url) => {
  return await axios.get(url, headers());
};

export const loadRemix = async (projectIdentifier, accessToken) => {
  return await axios.get(
    `${host}/api/projects/${projectIdentifier}/remix`,
    headers(accessToken),
  );
};

export const getRemixes = async (projectIdentifier, accessToken) => {
  return await axios.get(
    `${host}/api/projects/${projectIdentifier}/remixes`,
    headers(accessToken),
  );
};

export const createRemix = async (project, accessToken) => {
  return await axios.post(
    `${host}/api/projects/${project.identifier}/remix`,
    { project },
    headers(accessToken),
  );
};

export const readProject = async (projectIdentifier, locale, accessToken) => {
  const queryString = locale ? `?locale=${locale}` : "";
  return await axios.get(
    `${host}/api/projects/${projectIdentifier}${queryString}`,
    headers(accessToken),
  );
};

export const readProjectList = async (page, accessToken) => {
  return await axios.get(`${host}/api/projects`, {
    params: { page },
    ...headers(accessToken),
  });
};

export const setProjectFinishedState = async (
  projectIdentifier,
  accessToken,
  finishedState,
) => {
  return await axios.put(
    `${host}/api/projects/${projectIdentifier}/finished`,
    { finished: finishedState },
    headers(accessToken),
  );
};

export const getProjectFinishedState = async (
  projectIdentifier,
  accessToken,
) => {
  return await axios.get(
    `${host}/api/projects/${projectIdentifier}/finished`,
    headers(accessToken),
  );
};

export const getProjectContext = async (identifier, accessToken) => {
  return await axios.get(
    `${host}/api/projects/${identifier}/context`,
    headers(accessToken),
  );
};

export const uploadImages = async (projectIdentifier, accessToken, images) => {
  var formData = new FormData();

  images.forEach((image) => {
    formData.append("images[]", image, image.name);
  });

  return await axios.post(
    `${host}/api/projects/${projectIdentifier}/images`,
    formData,
    { ...headers(accessToken), "Content-Type": "multipart/form-data" },
  );
};

export const createError = async (
  projectIdentifier,
  userId,
  error,
  sendError = false,
) => {
  if (!sendError) {
    return;
  }
  const { errorMessage, errorType } = error;
  return await axios.post(
    `${host}/api/project_errors`,
    {
      error: errorMessage,
      error_type: errorType,
      project_id: projectIdentifier,
      user_id: userId,
    },
    undefined,
  );
};
